import firebase from 'firebase/app';
import 'firebase/storage';
import { useMemo } from 'react';
import '../config/initializeApp';

export type StorageReference = firebase.storage.Reference;
export type Storage = firebase.storage.Storage;

export function useStorage(): Storage {
  const storage = useMemo(() => {
    return firebase.storage();
  }, []);
  return storage;
}
