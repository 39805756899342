import firebase from 'firebase/app';
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { useRecoilStateLoadable } from 'recoil';
import { currentAuth } from '../recoils';

export function SessionManager({}) {
  const [currentAuthLoadable, setCurrentAuth] =
    useRecoilStateLoadable(currentAuth);

  // 認証状態を Subscribe する
  React.useEffect(() => {
    const auth = firebase.auth();

    // 開発環境でのみ自動ログインを試行
    const attemptDevinLogin = async (currentUser: firebase.User | null) => {
      if (
        process.env.NODE_ENV === 'development' &&
        process.env.REACT_APP_DEV_AUTO_LOGIN === 'true' &&
        !currentUser
      ) {
        try {
          await auth.signInWithEmailAndPassword(
            'devin-test@example.com',
            'DevinTest123!'
          );
          console.log('自動ログイン成功');
        } catch (error) {
          console.error('自動ログイン失敗', error);
        }
      }
    };

    return auth.onAuthStateChanged(user => {
      setCurrentAuth(user);
      attemptDevinLogin(user);
    });
  }, []);

  // 認証状態のセッションがないことが確認されたら、トップにリダイレクトする
  if (currentAuthLoadable.contents === null) {
    return <Redirect to="/" />;
  }

  return null;
}
