import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { loadingTeamMembers } from '../components/Dashboard';
import {
  activeMembersNum,
  getQuantity,
  teamCustomers,
  teamMemberIdsState
} from '../recoils';

interface Stats {
  /**
   * メンバーの合計人数
   */
  sum?: number;
  /**
   * 実際に「特典あり」とされている人数
   */
  has?: number;
  /**
   * 顧客情報に設定されている利用人数
   */
  max?: number;
  /**
   * これ以上、特典を利用できるか（今は上限がない仕様になっているので常に true になる）
   * 読み込み中は false
   */
  canIncrease: boolean;
  /**
   * 特典ありの人数が最大値を超過している（今は上限がない仕様になっているので常に false になる）
   * 読み込み中は false
   */
  over: boolean;
}

/**
 * メンバーの人数、特典のメンバーの人数、特典の上限数を取得する
 */
export function useStats(teamId: string): Stats {
  const membersNum = useRecoilValue(teamMemberIdsState(teamId)).length;
  const customerLoadable = useRecoilValueLoadable(teamCustomers(teamId));
  const quantity =
    customerLoadable.state === 'hasValue'
      ? getQuantity(customerLoadable.contents)
      : undefined;

  const has = useRecoilValue(activeMembersNum(teamId));

  const loading = useRecoilValue(loadingTeamMembers(teamId));

  return {
    sum: membersNum,
    has,
    max: quantity === null ? undefined : quantity,
    canIncrease: !loading,
    over: false
  };
}
