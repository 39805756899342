import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import firebase from 'firebase/app';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { useDebouncedCallback } from 'use-debounce';
import { endpoint } from '../env';
import {
  completeTagsAtom,
  popupAlert,
  teamMembersAtom,
  teamsAtom
} from '../recoils';
import { requestWithAuth } from '../store/requestWithAuth';
import { analytics } from '../utils/analytics';
import { merge } from '../utils/merge';
import { AutocompleteTags } from './AutocompleteTags';
import { Params } from './Dashboard';
import { PasswordTextField } from './PasswordTextField';

type CreateMember = {
  teamId: string;
  loginId: string;
  password: string;
  name: string;
  /**
   * "\n" 区切りで複数のタグを設定できる
   */
  tags: string;
  displayName: string;
  rights: boolean;
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingLeft: 32,
    '&>*': {
      marginTop: 32
    }
  },
  autocomplete: {
    width: 500
  }
}));

export function DashboardCreate() {
  const classes = useStyles();
  const { teamId } = useParams<Params>();
  const team = useRecoilValue(teamsAtom(teamId));

  // 入力内容
  const [loginId, setLoginId] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [name, setName] = React.useState('');
  const [tags, setTags] = React.useState(''); // "\n" 区切りで複数のタグを設定する
  const [displayName, setDisplayName] = React.useState('');
  const [rights, setRights] = React.useState(true);

  // バリデーション
  const invalidCharsInLoginId = /[^a-zA-Z0-9_]/.test(loginId); // ログイン ID に無効な文字列が含まれている
  const loginIdIsTooShort = loginId.length < 2; // ログイン ID が２文字未満である
  const passwordIsTooShort = password.length < 8; // パスワードが８文字未満である

  // team.loginId と loginId の組み合わせが別のユーザーに使われていないか確認する
  const loginIdRef = React.useRef(loginId);
  loginIdRef.current = loginId;
  const [usedLoginId, setUsedLoginId] = React.useState(false);
  const [checkLoginId] = useDebouncedCallback((loginId: string) => {
    if (!team) return;
    if (loginId !== loginIdRef.current) return; // 値が変わった
    const email = anonymousEmail(team.loginId, loginId);
    firebase
      .auth()
      .fetchSignInMethodsForEmail(email)
      .then(res => {
        if (loginId !== loginIdRef.current) return; // 値が変わった
        setUsedLoginId(res.length > 0); // ログイン方法が１つでも存在するなら、既に使われている
      })
      .catch(() => {
        if (loginId !== loginIdRef.current) return; // 値が変わった
        setUsedLoginId(false);
      });
  }, 250);
  React.useEffect(() => {
    if (loginId.length < 2) return;
    checkLoginId(loginId);
  }, [loginId]);

  // 送信・送信後はボタンを押せないようにする
  const [submit, setSubmit] = React.useState(false);
  const handleSubmit = useRecoilCallback(
    async ({ set }) => {
      setSubmit(true);
      analytics.createMember();
      // 新しいメンバーを作成してチームに追加する
      const params: CreateMember = {
        teamId,
        loginId,
        password,
        name,
        tags,
        displayName,
        rights
      };
      try {
        const member = await requestWithAuth<IMember>(
          endpoint + '/members',
          'POST',
          params
        );
        set(teamMembersAtom({ teamId, uid: member.uid }), member);
        set(completeTagsAtom(teamId), curr => merge(curr, member.tags));
        set(popupAlert, {
          severity: 'success',
          children: `${member.name} をチームに追加しました`
        });

        // 送信が成功したので、入力内容をクリアする
        setLoginId('');
        setPassword('');
        setName('');
        setTags('');
        setDisplayName('');
      } catch (error) {
        set(popupAlert, {
          severity: 'error',
          // @ts-expect-error TODO: Errorのタイプチェックを行う
          children: 'ユーザーの作成に失敗しました。' + error.message
        });
      }
      setSubmit(false);
    },
    [teamId, loginId, password, name, tags, displayName, rights]
  );

  return (
    <>
      <div className={classes.root}>
        {team?.enabled === false ? (
          <Alert severity="info">
            こちらではメンバー用アカウントの新規発行が可能です。アカウント有効化後、ご利用いただけるようになります。
          </Alert>
        ) : null}
        <Typography variant="h5">新しくメンバーを追加</Typography>
        <TextField
          variant="outlined"
          label="ニックネーム"
          value={displayName}
          helperText="ハックフォープレイ上で一般に公開されます。いつでも変更できます"
          onChange={e => setDisplayName(e.target.value)}
        />
        <TextField
          variant="outlined"
          label="名前（管理者用）"
          value={name}
          onChange={e => setName(e.target.value)}
          helperText="この名前は管理者しか見ることができません。いつでも変更できます"
        />
        <Typography variant="h6">任意のタグを設定できます</Typography>
        <AutocompleteTags
          teamId={teamId}
          onChange={(event: any, value: string[]) => setTags(value.join('\n'))}
        />
        <Typography variant="h6">
          ログイン用の ID とパスワードを設定して下さい
        </Typography>
        <TextField
          variant="outlined"
          disabled
          label="チーム"
          value={team?.loginId || ''}
          helperText="ログインに必要な文字列で、変更はできません"
        />
        <TextField
          variant="outlined"
          required
          label="ログイン ID"
          value={loginId}
          error={Boolean(
            loginId &&
              (invalidCharsInLoginId || loginIdIsTooShort || usedLoginId)
          )}
          helperText={
            invalidCharsInLoginId
              ? 'ログイン ID に使用できる文字は英数字または _ です。無効な文字が含まれています'
              : loginIdIsTooShort
              ? 'ログイン ID は英数字または _ （アンダースコア）で２文字以上である必要があります'
              : usedLoginId
              ? '既に使われている ID です。ログイン ID は人と同じにはできません'
              : ''
          }
          onChange={e => setLoginId(e.target.value)}
        />
        <PasswordTextField
          variant="outlined"
          required
          label="初期パスワード"
          value={password}
          error={Boolean(password && passwordIsTooShort)}
          onChange={e => setPassword(e.target.value)}
          helperText="パスワードは８文字以上である必要があります"
        />
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={rights}
                onChange={() => setRights(!rights)}
                name="rights"
                color="primary"
              />
            }
            label="すべてのアセットと授業動画を利用できるようにする"
          />
          <FormHelperText>
            {rights
              ? '利用期間に応じた課金が発生します'
              : '機能が制限されます。あとで有効化する必要があります'}
          </FormHelperText>
        </FormControl>

        <Button
          variant="contained"
          color="primary"
          disabled={
            invalidCharsInLoginId ||
            loginIdIsTooShort ||
            usedLoginId ||
            passwordIsTooShort ||
            submit ||
            !team?.enabled // 有効化前
          }
          onClick={handleSubmit}
        >
          メンバーを追加
        </Button>
      </div>
    </>
  );
}

/**
 * チームメンバーは Email を持っていないので、仮のアドレスらしきものを生成して一意にする
 * 実在するメールアドレスと衝突してはいけない
 * メールアドレスの形式が正しくないと、 Error: The email address is improperly formatted. エラーがスローされる
 * @param teamLoginId チームごとに割り当てられている一意な文字列
 * @param loginId メンバー作成時に自由に決められる一意な文字列
 */
export function anonymousEmail(teamLoginId: string, loginId: string) {
  return `${loginId}.${teamLoginId}@example.com`;
}
