import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  makeStyles,
  TextField,
  TextFieldProps,
  Typography
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import firebase from 'firebase/app';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { endpoint } from '../env';
import { currentAuth, popupAlert } from '../recoils';
import { HttpError, requestWithAuth } from '../store/requestWithAuth';
import { LoadingPage } from './LoadingPage';

type CreateTeam = {
  companyName: string;
  teamName: string;
  // email と adminName は Google OAuth の情報を使う
  // email: string;
  // adminName: string;
  loginId: string;
  question: string;
};

const defaultQuestions = [
  '授業構成・カリキュラムが知りたい',
  '料金について詳しく知りたい',
  '実際に授業で使って試してみたい'
];

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: 600,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: theme.spacing(4),
    '& > *': {
      marginTop: theme.spacing(2)
    }
  },
  question: {
    marginTop: theme.spacing(4),
    '& > *': {
      display: 'block'
    }
  }
}));

export function Register() {
  return (
    <React.Suspense fallback={<LoadingPage />}>
      <RegisterComponent />
    </React.Suspense>
  );
}

function RegisterComponent() {
  const cn = useStyles();
  const history = useHistory();
  const userInfo = useRecoilValue(currentAuth);

  const [value, setValue] = React.useState<CreateTeam>({
    companyName: '',
    loginId: '',
    question: '',
    teamName: ''
  });
  const loginIdIsTooShort = value.loginId.length < 2;
  const loginIdIsInvalid = /[^A-Za-z0-9_]/.test(value.loginId);

  const [sending, setSending] = React.useState(false); //送信中のフラグ
  const [collidedLoginId, setCollidedLoginId] = React.useState(''); // loginId が既に使われていた場合、その loginId を保持する
  const [serverError, setServerError] = React.useState(false); // 予期せぬサーバーエラーが発生した時のフラグ

  const loginIdIsCollided = Boolean(
    value.loginId && collidedLoginId === value.loginId
  );

  const makeSet: (key: keyof CreateTeam) => TextFieldProps['onChange'] =
    key => event => {
      setValue({ ...value, [key]: event.target.value });
    };
  const [questions, setQuestions] = React.useState<string[]>([]);
  const makeCheck = (text: string) => (e: any, checked: boolean) => {
    const next = checked
      ? questions.concat(text)
      : questions.filter(t => t !== text);
    setQuestions(next);
  };

  // 新しいチームを仮登録する
  const send = useRecoilCallback(
    async ({ set }) => {
      try {
        setSending(true);
        setServerError(false);

        const question = questions.join() + '\n' + value.question;
        await requestWithAuth(endpoint + '/teams', 'POST', {
          ...value,
          question
        });

        set(popupAlert, {
          severity: 'success',
          children:
            'ありがとうございます。１営業日以内に Google アカウント宛にメールを差し上げます'
        });

        history.push('/'); // 登録できたらトップページに遷移する
      } catch (error) {
        if ((error as HttpError).response?.status === 422) {
          // loginId が被っている場合は 422 Unprocessable Entity を返す
          setCollidedLoginId(value.loginId);
        } else {
          setServerError(true);
        }
      }
      setSending(false);
    },
    [questions, value]
  );

  if (!userInfo?.email) {
    return (
      <div className={cn.container}>
        <Typography>
          大変申し訳ありませんが、お客様の Google
          アカウントにはメールアドレスが紐づけられていないためご利用いただけません。
          <br />
          恐れ入りますが、別の Google アカウントでログインしてください。
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => firebase.auth().signOut()}
        >
          ログアウト
        </Button>
      </div>
    );
  }

  return (
    <div className={cn.container}>
      <Typography variant="h4" color="textSecondary">
        法人プランのお問い合わせ
      </Typography>
      <Typography variant="body1" color="textSecondary">
        {userInfo?.displayName || <i>お名前が登録されていません</i>} 様
      </Typography>
      <Typography variant="body1" color="textSecondary">
        この度は HackforPlay 法人プランをご検討いただきありがとうございます。
        <br />
        下記項目をご入力いただき、送信ボタンを押してください。
        <br />
        <i>{userInfo.email}</i> 宛のメールにて回答させていただきます。
      </Typography>
      <TextField
        required
        fullWidth
        autoFocus
        label="貴法人の正式名称"
        value={value.companyName}
        onChange={makeSet('companyName')}
      />
      <TextField
        required
        fullWidth
        label="貴事業の通称"
        helperText="ホームページ等で用いられている貴社の事業（サービス）の名称をご入力下さい"
        value={value.teamName}
        onChange={makeSet('teamName')}
      />
      <TextField
        required
        fullWidth
        label="ご希望のチーム ID"
        helperText={
          loginIdIsCollided
            ? 'このチーム ID は既に使用されています。申し訳ありませんが、違う ID を入力してください'
            : value.loginId && loginIdIsInvalid
            ? 'チーム ID は半角の英数字または _ のみ可となっております。ご利用いただけない文字が含まれています'
            : value.loginId && loginIdIsTooShort
            ? 'チーム ID は２文字以上必要です'
            : '生徒が覚えやすく入力しやすい、短い単語にされると便利です。半角の英数字と _ が利用可能です'
        }
        error={
          Boolean(value.loginId) &&
          (loginIdIsInvalid || loginIdIsTooShort || loginIdIsCollided)
        }
        value={value.loginId}
        onChange={makeSet('loginId')}
      />
      <div className={cn.question}>
        <Typography variant="body1" color="textSecondary">
          ご質問
        </Typography>
        {defaultQuestions.map(text => (
          <FormControlLabel
            key={text}
            control={
              <Checkbox
                checked={questions.includes(text)}
                onChange={makeCheck(text)}
                name={text}
                color="secondary"
              />
            }
            label={text}
          />
        ))}
      </div>
      <TextField
        fullWidth
        multiline
        label="その他のご質問"
        helperText="ご質問やご意見等、ご自由にお書きください。改行も可能です"
        value={value.question}
        onChange={makeSet('question')}
      />
      {serverError ? (
        <Alert severity="error">
          予期せぬエラーが発生しました。ただ今原因を調査しておりますが、お急ぎの場合は下記までご連絡下さい。
          <a href="mailto:support@hackforplay.com">support@hackforplay.com</a>
        </Alert>
      ) : null}
      <div>
        <Button
          variant="contained"
          color="primary"
          disabled={
            sending ||
            !value.companyName ||
            !value.loginId ||
            !value.teamName ||
            loginIdIsInvalid ||
            loginIdIsTooShort ||
            loginIdIsCollided
          }
          onClick={send}
          startIcon={
            sending ? <CircularProgress color="primary" size={16} /> : null
          }
        >
          送信
        </Button>
      </div>
    </div>
  );
}
