import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  Typography
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import * as React from 'react';
import 'react-credit-cards/es/styles.scss';
import { useParams } from 'react-router-dom';
import {
  useRecoilCallback,
  useRecoilValue,
  useRecoilValueLoadable
} from 'recoil';
import Stripe from 'stripe';
import { endpoint } from '../env';
import { useStorage } from '../hooks/useStorage';
import { useStats } from '../hooks/useStats';
import { teamCustomers, teamsAtom } from '../recoils';
import { requestWithAuth } from '../store/requestWithAuth';
import { CustomerPortalLink } from './CustomerPortalLink';
import { Params } from './Dashboard';

export interface DashboardPaymentsProps {}

const useStyles = makeStyles(theme => ({
  root: {
    padding: 32,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > *': {
      marginBottom: 32
    }
  },
  divider: {
    marginLeft: -32,
    width: 'calc(100% + 64px)'
  },
  buttonContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5)
    }
  },
  downloadLink: {
    textDecoration: 'underline'
  }
}));

export function DashboardPayments(props: DashboardPaymentsProps) {
  const classes = useStyles();

  const { teamId } = useParams<Params>();
  const teamLoadable = useRecoilValueLoadable(teamsAtom(teamId));
  const isTeamDisabled =
    teamLoadable.state === 'hasValue' &&
    teamLoadable.contents?.enabled === false;

  // customerが削除されているのはおかしい
  const customerLoadable = useRecoilValueLoadable(teamCustomers(teamId));
  const isCustomerDeleted =
    customerLoadable.state === 'hasValue' && customerLoadable.contents?.deleted;

  if (isCustomerDeleted) {
    return (
      <div className={classes.root}>
        <Typography variant="h6">
          お客様のアカウントに問題が発生しています。お手数ですが、ハックフォープレイ社にお問い合わせ下さい
        </Typography>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Quantity />
      <Typography variant="h6">お支払い情報</Typography>
      {isTeamDisabled ? (
        <Alert severity="warning">
          この度はお申し込みありがとうございます。貴社は現在、仮登録いただいている状態です。
          <br />
          アカウントを有効化するため、下記リンクよりお支払い情報のご入力をお願いいたします。
        </Alert>
      ) : null}
      <CustomerPortalLink teamId={teamId}>
        {isTeamDisabled ? 'お支払い情報を入力する' : 'お支払い情報を変更する'}
      </CustomerPortalLink>
      {!isTeamDisabled && <InvoiceDetailsSection teamId={teamId} />}
    </div>
  );
}

function InvoiceDetailsSection({ teamId }: { teamId: string }) {
  const classes = useStyles();
  const storage = useStorage();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<Error | null>(null);

  const handleDownload = async (date: string) => {
    setLoading(true);
    setError(null);
    try {
      // Ensure we have the full YYYY-MM-DD.csv format
      const ref = storage.ref(`invoice_details/${teamId}/${date}.csv`);
      const url = await ref.getDownloadURL();
      const response = await fetch(url);
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = `invoice_details_${date}.csv`; // Keep original filename format
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(downloadUrl);
    } catch (err) {
      setError(err as Error);
    } finally {
      setLoading(false);
    }
  };

  const [files, setFiles] = React.useState<string[]>([]);

  React.useEffect(() => {
    const fetchFiles = async () => {
      try {
        const ref = storage.ref(`invoice_details/${teamId}`);
        const result = await ref.listAll();
        // Sort files by name (YYYY-MM-DD.csv) in descending order and take the first 12
        // Filter and sort files in YYYY-MM-DD.csv format
        const sortedFiles = result.items
          .map(item => item.name)
          .filter(name => /^\d{4}-\d{2}-\d{2}\.csv$/.test(name))
          .map(name => name.replace('.csv', ''))
          .sort((a, b) => b.localeCompare(a))
          .slice(0, 12);
        setFiles(sortedFiles);
      } catch (error) {
        console.error('Error fetching invoice files:', error);
        setFiles([]);
      }
    };

    fetchFiles();
  }, [storage, teamId]);

  return (
    <>
      <Typography variant="h6">請求明細のダウンロード</Typography>
      <Box className={classes.buttonContainer}>
        {files.map(date => (
          <Button
            key={date}
            variant="text"
            color="primary"
            className={classes.downloadLink}
            onClick={() => handleDownload(date)}
            disabled={loading}
          >
            {date}
          </Button>
        ))}
      </Box>
      {error && (
        <Alert severity="error">
          ダウンロードに失敗しました: {error.message}
        </Alert>
      )}
    </>
  );
}

export function Quantity() {
  // 現在の席数などを確認する
  const { teamId } = useParams<Params>();
  const { max, has } = useStats(teamId);
  const [processing, setProcessing] = React.useState(false);

  // 利用人数を更新する
  // TODO: useRecoilCallback のパラメータは 0.0.9 で変更された
  const handleReload = useRecoilCallback(
    async ({ set }) => {
      setProcessing(true);
      try {
        const customer = await requestWithAuth<Stripe.Customer>(
          endpoint + `/teamCustomers?teamId=${teamId}`
        );
        set(teamCustomers(teamId), customer);
      } catch (error) {
        console.error(error);
      } finally {
        setProcessing(false);
      }
    },
    [teamId]
  );

  // 有効化前は不要な情報なので出さない
  const team = useRecoilValue(teamsAtom(teamId));
  if (team?.enabled === false) {
    return null;
  }

  // リロード中
  if (processing) {
    return <CircularProgress />;
  }

  return (
    <div>
      <Typography variant="body1">
        現在の有効利用人数{max === undefined ? 'を取得中' : `は ${max} 人`}です
      </Typography>
      {max !== has ? (
        <Alert severity="info">
          利用人数が変更されている可能性があります。
          <Button variant="contained" color="primary" onClick={handleReload}>
            再読み込み
          </Button>
        </Alert>
      ) : null}
    </div>
  );
}
